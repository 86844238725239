import { useMutation } from "react-query";
import { main_url } from "../../constants";

import axios from "axios";

export const usePost = (url: string, token?: string) => {
  const {
    data,
    error,
    reset,
    status,
    mutate,
    mutateAsync,
    isError,
    isIdle,
    isLoading,
    isSuccess,
    isPaused,
  } = useMutation(
    async (collectedData: any) => {
      //check if token is available

      if (!token) {
        return axios
          .post(`${main_url}${url}`, JSON.stringify(collectedData), {
            headers: {
              "Content-Type": "application/json",
            },
            timeout: 30000,
          })
          .catch((err) => {
            console.log(err);
          });
      }

      return axios.post(`${main_url}${url}`, collectedData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onError: (err: any) => {
        return err.response.data;
      },
    }
  );

  return {
    data,
    error,
    reset,
    status,
    mutate,
    mutateAsync,
    isError,
    isIdle,
    isLoading,
    isSuccess,
    isPaused,
  };
};
